/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconExpandOutlineSvgIcon: SVGIcon = {
  name: 'expand_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/enter-right-outline</title><g id="Icon/Outline/enter-right-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.159800, -0.000000)" fill="currentColor" fill-rule="nonzero"><g id="enter-right-outline"><path d="M5.97000002,6.93060003 L7.03050003,5.87010002 L13.1595001,11.9991 L7.03050003,18.1296001 L5.97000002,17.0691001 L10.2885,12.7491001 L0,12.7491001 L0,11.2491 L10.2885,11.2491 L5.97000002,6.93060003 Z M14.1598,3 L21.1598,3 L21.1598,21 L14.1598,21 L14.1598,3 Z M1.65979995,1.5 L1.65979995,5.24999992 L0.159799955,5.24999992 L0.159799955,1.5 C0.159799955,0.671999903 0.831799908,-3.55271368e-15 1.65979995,-3.55271368e-15 L22.6598,0 C23.4878,0 24.1598,0.671999903 24.1598,1.5 L24.1598,22.5 C24.1598,23.328 23.4878,24 22.6598,24 L1.65979995,24 C0.831799908,24 0.159799955,23.328 0.159799955,22.5 L0.159799955,18.75 L1.65979995,18.75 L1.65979995,22.5 L22.6598,22.5 L22.6598,1.5 L1.65979995,1.5 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
