/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconDocumentExportFillSvgIcon: SVGIcon = {
  name: 'document_export_fill',
  content:
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.25171 0.4V23.6C2.25171 23.82 2.43171 24 2.65171 24H21.3484C21.5717 24 21.7484 23.82 21.7484 23.6V6.8C21.7484 6.74667 21.7384 6.69667 21.7184 6.64667C21.6984 6.59667 21.6684 6.55333 21.6317 6.51667L15.2117 0.116667C15.175 0.08 15.1317 0.05 15.0817 0.03C15.0317 0.01 14.9784 0 14.925 0H2.65171C2.42838 0 2.25171 0.18 2.25171 0.4ZM14.6684 2.02333L19.7217 7.05667H14.6684V2.02333ZM9.44171 19.09L7.99504 17.6633L12.3784 13.34H10.3784C9.81504 13.34 9.35504 12.8867 9.35504 12.3333C9.35504 11.78 9.81504 11.3233 10.3784 11.3233H15.8684V16.74C15.8684 17.2967 15.4084 17.75 14.845 17.75C14.5717 17.75 14.315 17.6433 14.1217 17.4533C13.9284 17.2633 13.8217 17.01 13.8217 16.74V14.7667L9.43838 19.09H9.44171Z" fill="black"/></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
