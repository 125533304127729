/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconErrorFillSvgIcon: SVGIcon = {
  name: 'error_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/error</title><g id="Icon/Fill/error" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Icon" fill="currentColor" fill-rule="nonzero"><g id="circle-delete-outline"><path fill="#C52A1A" d="M7.77094198,0 L16.4378418,0.0756920936 C16.8357333,0.0791644403 17.2159488,0.24055636 17.4948453,0.524363472 L23.569787,6.70626904 C23.8486835,6.99007616 24.0034151,7.37305046 24,7.77094198 L23.9243079,16.4378418 C23.9208356,16.8357333 23.7594436,17.2159488 23.4756365,17.4948453 L17.293731,23.569787 C17.0099238,23.8486835 16.6269495,24.0034151 16.229058,24 L7.56215821,23.9243079 C7.16426669,23.9208356 6.78405115,23.7594436 6.50515468,23.4756365 L0.430212993,17.293731 C0.151316521,17.0099238 -0.00341514192,16.6269495 0,16.229058 L0.0756920936,7.56215821 C0.0791644403,7.16426669 0.24055636,6.78405115 0.524363472,6.50515468 L6.70626904,0.430212993 C6.99007616,0.151316521 7.37305046,-0.00341514192 7.77094198,0 Z M16.0466375,6.80938864 L12.0003237,10.8557025 L7.95400989,6.80938864 L6.80917283,7.95422572 L10.8554866,12.0005395 L6.80917283,16.0468534 L7.95400989,17.1906114 L12.0003237,13.1442975 L16.0466375,17.1906114 L17.1914746,16.0468534 L13.1451608,12.0005395 L17.1914746,7.95422572 L16.0466375,6.80938864 Z" id="Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
