import { Injectable } from '@angular/core';
import { IconSettingsService } from '@progress/kendo-angular-icons';
import { SVGIcon } from '@progress/kendo-svg-icons';
import { AppPwcIcons } from '@app-shared/icons/app-pwc.icons';

@Injectable()
export class GridFilterIconService extends IconSettingsService {
  private svgDictionary: Record<string, SVGIcon> = {
    filter: AppPwcIcons.fill.filterIcon,
    'caret-alt-down': AppPwcIcons.outline.downChevronIcon,
    'caret-alt-up': AppPwcIcons.outline.upChevronIcon
  };

  public override getSvgIcon(svgIconName: string): SVGIcon {
    return this.svgDictionary[svgIconName] || super.getSvgIcon(svgIconName);
  }
}
