<div class="banner-container" [ngClass]="breadCrumbs && breadCrumbs.length > 0 ? 'banner-with-breadcrumbs' : ''">
  <div class="content">
    <div class="header">
      <h1 class="title">{{ title }}</h1>
      @for (item of metadata; let last = $last, i = $index; track trackByFn(i)) {
        <h2 class="details">
          {{ item }}
          @if (!last) {
            <span> | </span>
          }
        </h2>
      }
    </div>
    @if (breadCrumbs && breadCrumbs.length > 0) {
      <div class="breadcrumb-container">
        <kendo-breadcrumb [items]="breadCrumbs" [separatorSVGIcon]="appPwcIcons.outline.rightChevron">
          <ng-template kendoBreadCrumbItemTemplate let-item let-index="index">
            <div class="item" (click)="navigateToPage(item)" [ngClass]="item.disabled ? 'disabled' : ''">
              <span class="Appkit4-icon icon-folder-closed-outline"></span>
              <span class="breadCrumb-label">{{ item.name }}</span>
            </div>
          </ng-template>
        </kendo-breadcrumb>
      </div>
    }
  </div>
  <div class="image-banner-container">
    <img ngSrc="banner-bg.svg" alt="banner image" fill priority />
  </div>
</div>
