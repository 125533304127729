import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppPwcIcons } from '@app-shared/icons/app-pwc.icons';
import { NavigationStateService } from '@app-shared/services/navigation-state.service';
import { DrawerItemExpandedFn, DrawerSelectEvent } from '@progress/kendo-angular-layout';
import { MenuSelectEvent } from '@progress/kendo-angular-menu';
import { NAVIGATION_COLLAPSED_ITEM, NAVIGATION_ITEM } from './navigation-item.constant';

@Component({
  selector: 'pwc-fdm-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  expandedIndices = [0];
  selectedItem = 0;
  isExpanded = false;

  items = NAVIGATION_ITEM;
  items_collapsed = NAVIGATION_COLLAPSED_ITEM;

  appPwcIcons = AppPwcIcons;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private navigationStateService: NavigationStateService
  ) {}

  ngOnInit(): void {
    this.toggleSelectedValues(this.location.path());
    this.location.onUrlChange(url => {
      this.toggleSelectedValues(url);
    });
    this.detectChanges();
  }

  private detectChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  isItemExpanded: DrawerItemExpandedFn = (item): boolean => {
    return this.expandedIndices.indexOf(item.id) >= 0;
  };

  selectNavItemHandler(ev: DrawerSelectEvent): void {
    this.selectedItem = ev.item.id;
    this.items[this.selectedItem].selected = true;
    if (ev.item.routerLink) {
      this.navigationStateService.setNavigationState({ activeTab: ev.item.activeIndex });
      this.router.navigateByUrl(ev.item.routerLink);
    }
    if (this.expandedIndices.indexOf(this.selectedItem) >= 0) {
      this.expandedIndices = this.expandedIndices.filter(id => id !== this.selectedItem);
    } else {
      this.expandedIndices.push(this.selectedItem);
    }
  }

  toggleSelectedValues(url: string): void {
    this.items.forEach(item => (item.selected = false));
    if (url.includes('home') || url.includes('fund')) {
      this.selectedItem = 0;
    } else if (url.includes('scenario')) {
      this.selectedItem = 1;
    } else if (url.includes('upload')) {
      this.selectedItem = 2;
    } else if (url.includes('appuser')) {
      this.selectedItem = 10;
    } else if (url.includes('general-ledger')) {
      this.selectedItem = 6;
    } else {
      this.selectedItem = 11;
    }
    this.items[this.selectedItem].selected = true;
    this.detectChanges();
  }

  selectMenuItem(ev: MenuSelectEvent): void {
    this.selectedItem = ev.item.id;
    this.items[this.selectedItem].selected = true;
    if (ev.item.routerLink) {
      this.navigationStateService.setNavigationState({ activeTab: ev.item.activeIndex });
      this.router.navigateByUrl(ev.item.routerLink);
    }
  }
}
