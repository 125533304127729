import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppPwcIcons } from '@app-shared/icons/app-pwc.icons';
import { BreadCrumbModel } from '@app-shared/models/breadcrumb.model';
import { NavigationStateService } from '@app-shared/services/navigation-state.service';

@Component({
  selector: 'pwc-fdm-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerComponent {
  @Input() title = '';
  @Input() metadata: string[] = [];
  @Input() breadCrumbs: BreadCrumbModel[] = [];

  // FIXME Need to be replaced with Kendo component
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() bannerItemClicked = new EventEmitter<any>();

  appPwcIcons = AppPwcIcons;

  constructor(
    private router: Router,
    private navigationStateService: NavigationStateService
  ) {}

  trackByFn(index: number): number {
    return index;
  }

  navigateToPage(breadCrumb: BreadCrumbModel): void {
    if (breadCrumb.path) {
      this.navigationStateService.setNavigationState(breadCrumb.state);
      this.router.navigate([breadCrumb.path]);
      this.bannerItemClicked.emit(breadCrumb.state);
    }
  }
}
