{
  "name": "fdm-client",
  "version": "0.0.0",
  "scripts": {
    "branch:update": "git fetch --all --prune & git branch -vv && git pull --verbose",
    "build": "npm run build-development",
    "build-development": "ng build --verbose",
    "build-production-with-maps": "ng build --verbose --configuration production-with-maps",
    "build-production": "ng build --verbose --configuration production",
    "code:all:check": "npm run format:files:check && npm run lint:scss:check && npm run lint:ts:check",
    "code:all:fix": "npm run format:files:fix && npm run lint:scss:fix && npm run lint:ts:fix ",
    "format:files:check": "prettier --check \"src/**/*.{js,json,md,ts,html,component.html,scss}\" --log-level warn",
    "format:files:fix": "prettier --write \"src/**/*.{js,json,md,ts,html,component.html,scss}\" --log-level warn",
    "lint": "npm run code:all:fix",
    "lint:ts:check": "ng lint",
    "lint:ts:fix": "ng lint --fix",
    "lint:scss:check": "npx stylelint \"src/**/*.scss\"",
    "lint:scss:fix": "npx stylelint \"src/**/*.scss\" --fix",
    "start": "ng serve -o  --verbose --hmr  --configuration=development",
    "support:clean": "npx rimraf .angular/ dist/ && npm cache clean --force",
    "support:display:versions": "ng version",
    "support:install:libs": "npm run support:clean && npm install --verbose",
    "support:update:lefthooks": "npx lefthook install",
    "support:lint:check:tohtmlfile": "npx eslint src/** -f html --o eslint-issues.html"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@appkit4/angular-components": "4.13.0",
    "@appkit4/styles": "^4.10.0",
    "@progress/kendo-angular-buttons": "^16.11.0",
    "@progress/kendo-angular-common": "^16.11.0",
    "@progress/kendo-angular-dateinputs": "^16.11.0",
    "@progress/kendo-angular-dialog": "^16.11.0",
    "@progress/kendo-angular-dropdowns": "^16.11.0",
    "@progress/kendo-angular-excel-export": "^16.11.0",
    "@progress/kendo-angular-filter": "^16.11.0",
    "@progress/kendo-angular-grid": "^16.11.0",
    "@progress/kendo-angular-icons": "^16.11.0",
    "@progress/kendo-angular-indicators": "^16.11.0",
    "@progress/kendo-angular-inputs": "^16.11.0",
    "@progress/kendo-angular-intl": "16.11.0",
    "@progress/kendo-angular-l10n": "16.11.0",
    "@progress/kendo-angular-label": "^16.11.0",
    "@progress/kendo-angular-layout": "^16.11.0",
    "@progress/kendo-angular-menu": "^16.11.0",
    "@progress/kendo-angular-navigation": "^16.11.0",
    "@progress/kendo-angular-notification": "^16.11.0",
    "@progress/kendo-angular-pdf-export": "^16.11.0",
    "@progress/kendo-angular-popup": "^16.11.0",
    "@progress/kendo-angular-progressbar": "^16.11.0",
    "@progress/kendo-angular-tooltip": "^16.11.0",
    "@progress/kendo-angular-treeview": "^16.11.0",
    "@progress/kendo-angular-upload": "^16.11.0",
    "@progress/kendo-angular-utils": "^16.11.0",
    "@progress/kendo-data-query": "^1.7.0",
    "@progress/kendo-drawing": "^1.21.2",
    "@progress/kendo-font-icons": "^3.3.0",
    "@progress/kendo-licensing": "^1.3.5",
    "@progress/kendo-theme-default": "^9.1.0",
    "angular-oauth2-oidc": "^17.0.2",
    "lodash-es": "^4.17.21",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular/build": "^18.2.12",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@angular/localize": "^18.2.13",
    "@types/lodash-es": "^4.17.12",
    "angular-eslint": "18.4.0",
    "eslint": "^9.17.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-plugin-prettier": "^5.2.1",
    "lefthook": "^1.9.0",
    "prettier": "^3.4.2",
    "prettier-eslint": "^16.3.0",
    "rimraf": "^6.0.1",
    "stylelint": "^16.11.0",
    "stylelint-config-clean-order": "^6.1.0",
    "stylelint-config-prettier-scss": "^1.0.0",
    "stylelint-config-standard-scss": "^13.1.0",
    "typescript": "~5.4.2",
    "typescript-eslint": "^8.19.1"
  }
}
