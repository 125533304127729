/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconTreeViewFillSvgIcon: SVGIcon = {
  name: 'tree_view_fill',
  content:
    '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.0884 15.56H20.5817C20.995 15.56 21.3317 15.2233 21.3317 14.81V9.31C21.3317 8.89667 20.995 8.56 20.5817 8.56H15.0884C14.675 8.56 14.3384 8.89667 14.3384 9.31V10.9433H7.22837V7H8.90837C9.32171 7 9.65837 6.66333 9.65837 6.25V0.75C9.65837 0.336667 9.32171 0 8.90837 0H3.41504C3.00171 0 2.66504 0.336667 2.66504 0.75V6.25C2.66504 6.66333 3.00171 7 3.41504 7H5.06504V21.6H14.3417V23.25C14.3417 23.6633 14.6784 24 15.0917 24H20.585C20.9984 24 21.335 23.6633 21.335 23.25V17.75C21.335 17.3367 20.9984 17 20.585 17H15.0917C14.6784 17 14.3417 17.3367 14.3417 17.75V19.4367H7.23171V13.1133H14.3417V14.8133C14.3417 15.2267 14.6784 15.5633 15.0917 15.5633L15.0884 15.56Z" fill="black"/></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
