/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcNotificationOutlineSvgIcon: SVGIcon = {
  name: 'notification_outline',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Outline/notification-outline</title><g id="Icon/Outline/notification-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(-0.000000, 0.750000)" fill="currentColor" fill-rule="nonzero"><g id="notification"><path d="M24,15.4010001 L24,19.4990001 L0,19.4990001 L0,15.4010001 L2.69600001,14.1750001 L5.04200002,6.61100003 C5.43000002,5.35700002 6.59000003,4.50200002 7.90300001,4.50000002 L16.0980001,4.50000002 C17.41,4.50200002 18.5700001,5.35700002 18.9580001,6.61100003 L21.3040001,14.1750001 L24,15.4010001 Z M22.5,17.9990001 L22.5,16.3670001 L20.6830001,15.5400001 L20.0700001,15.262 L19.8710001,14.6190001 L17.5260001,7.05600003 C17.3300001,6.42400002 16.7540001,6.00000003 16.093,6.00000003 L7.90700006,6.00000003 C7.24600003,6.00000003 6.67000003,6.42400002 6.47400003,7.05600003 L4.12900001,14.6190001 L3.93000002,15.262 L3.31700002,15.5400001 L1.50000001,16.3670001 L1.50000001,17.9990001 L22.5,17.9990001 Z M16.2816001,21.0000001 C15.1992001,21.9288001 13.8060001,22.5000001 12.2664001,22.5000001 C10.7268,22.5000001 9.33360004,21.9288001 8.25000003,21.0000001 L16.2816001,21.0000001 Z M8.25000003,3.75000002 C8.25000003,1.67893219 9.92893227,0 12.0000001,0 C14.0710678,0 15.75,1.67893219 15.75,3.75000002 L14.25,3.75000002 C14.25,2.50735932 13.2426407,1.5 12.0000001,1.5 C10.7573594,1.5 9.75000003,2.50735932 9.75000003,3.75000002 L8.25000003,3.75000002 Z" id="Combined-Shape"/></g></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
