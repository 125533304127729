import {
  Component,
  Input,
  TemplateRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { NotificationModel } from '@app-shared/models/notification.model';
import { SVGIcon } from '@progress/kendo-svg-icons';
import { AppPwcIcons } from '@app-shared/icons/app-pwc.icons';

@Component({
  selector: 'pwc-fdm-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements AfterViewInit {
  @ViewChild('notification', { read: TemplateRef })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public notificationTemplate!: TemplateRef<any>;
  public notificationReference!: NotificationRef;

  @Input() set notification(value: NotificationModel) {
    if (this.templateInitialized) {
      this.setNotification(value);
    } else {
      this.pendingNotification = value;
    }
  }

  private templateInitialized = false;
  private pendingNotification?: NotificationModel;
  notificationValue: NotificationModel = {} as NotificationModel;
  svgIcon!: SVGIcon;
  appPwcIcons = AppPwcIcons;
  closeIcon: SVGIcon = this.appPwcIcons.outline.xIcon;
  showSuccessIcon = false;
  showErrorIcon = false;

  constructor(
    protected notificationService: NotificationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    if (this.notificationTemplate) {
      this.templateInitialized = true;
      if (this.pendingNotification) {
        this.setNotification(this.pendingNotification);
        this.pendingNotification = undefined;
      }
    }
  }

  private setNotification(value: NotificationModel): void {
    if (this.isValidNotification(value)) {
      this.notificationValue = value;
      this.svgIcon = this.getIcon(value.icon) || this.appPwcIcons.outline.notificationIcon;
      this.showSuccessIcon = value.icon === 'successIcon';
      this.showErrorIcon = value.icon === 'errorIcon';
      this.createNotificationHandler();
    }
  }

  private detectChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  private getIcon(iconName?: string): SVGIcon | undefined {
    const iconMapping: Record<string, SVGIcon> = {
      alertIcon: this.appPwcIcons.fill.alertIcon,
      notificationIcon: this.appPwcIcons.outline.notificationIcon,
      successIcon: this.appPwcIcons.fill.successIcon,
      errorIcon: this.appPwcIcons.fill.cancelIcon
    };
    return iconName ? iconMapping[iconName] : undefined;
  }

  private isValidNotification(value: NotificationModel): boolean {
    return !!(value && value.title && value.message);
  }

  close(): void {
    if (this.notificationReference) {
      this.notificationReference.hide();
    }
  }

  createNotificationHandler(): void {
    // Hide the previous notification if it exists
    if (this.notificationReference) {
      this.notificationReference.hide();
    }

    if (this.notificationTemplate) {
      this.notificationReference = this.notificationService.show({
        content: this.notificationTemplate,
        position: { horizontal: 'right', vertical: 'top' },
        hideAfter: 10000,
        width: 360
      });
      this.detectChanges();
    }
  }
}
