<div id="sidebar" class="sidebar-container">
  @if (isExpanded) {
    <kendo-drawer-container>
      <kendo-drawer
        #drawer
        [items]="items"
        mode="push"
        [mini]="true"
        [expanded]="isExpanded"
        (select)="selectNavItemHandler($event)"
        [autoCollapse]="false"
        [isItemExpanded]="isItemExpanded">
      </kendo-drawer>
    </kendo-drawer-container>
  } @else {
    <kendo-menu [items]="items_collapsed" [vertical]="true" (select)="selectMenuItem($event)">
      <ng-template kendoMenuItemLinkTemplate let-item="item" let-index="index">
        @if (item.svgIcon) {
          @if (item.items && item.items.length) {
            <span
              [kendoMenuItemLink]="index"
              [ngClass]="{ 'expanable-items': item.items && item.items.length, active: item.id === selectedItem }">
              <span kendoButton [svgIcon]="item.svgIcon">
                <span [kendoMenuExpandArrow]="index"></span>
              </span>
            </span>
          } @else {
            <div kendoTooltip class="app-tooltip" [position]="'right'">
              <span
                [kendoMenuItemLink]="index"
                [ngClass]="{ 'expanable-items': item.items && item.items.length, active: item.id === selectedItem }">
                <span kendoButton [svgIcon]="item.svgIcon" [title]="item.text"></span>
              </span>
            </div>
          }
        } @else {
          <span [kendoMenuItemLink]="index" [ngClass]="item.id === selectedItem ? 'active' : ''">
            <div class="submenu-link">{{ item.text }}</div>
          </span>
        }
      </ng-template>
    </kendo-menu>
  }
  <div class="toggle-container" (click)="this.isExpanded = !this.isExpanded">
    @if (!isExpanded) {
      <button kendoButton [svgIcon]="appPwcIcons.outline.expandIcon"></button>
    } @else {
      <button kendoButton [svgIcon]="appPwcIcons.outline.collapseIcon">Collapse</button>
    }
  </div>
</div>
