import { AppPwcIcons } from '@app-shared/icons/app-pwc.icons';

export const NAVIGATION_ITEM = [
  {
    text: 'Funds',
    routerLink: '/fund',
    svgIcon: AppPwcIcons.fill.menuViewIcon,
    id: 0,
    selected: true
  },
  {
    text: 'Calculations',
    routerLink: '/scenario',
    svgIcon: AppPwcIcons.fill.calculatorIcon,
    id: 1,
    selected: false
  },
  {
    text: 'Source Data',
    svgIcon: AppPwcIcons.fill.sourceUploadIcon,
    id: 2,
    selected: false
  },
  {
    text: 'Transaction Uploads',
    routerLink: '/upload/sourcedata-list/transactional-sourcedata',
    activeIndex: 0,
    id: 3,
    parentId: 2,
    selected: false
  },
  {
    text: 'Point in Time Uploads',
    routerLink: '/upload/sourcedata-list/point-in-time-sourcedata',
    id: 4,
    activeIndex: 1,
    parentId: 2,
    selected: false
  },
  {
    text: 'Miscellaneous Uploads',
    routerLink: '/upload/sourcedata-list/miscellaneous-sourcedata',
    id: 5,
    activeIndex: 2,
    parentId: 2,
    selected: false
  },
  {
    text: 'Global Mapping',
    svgIcon: AppPwcIcons.fill.globeIcon,
    id: 6,
    selected: false
  },
  {
    text: 'Global General Ledger Mapping',
    routerLink: '/general-ledger/global-mapping',
    id: 7,
    parentId: 6,
    selected: false
  },
  {
    text: 'FX Gain/Loss Mapping',
    routerLink: '/general-ledger/fx-mapping',
    id: 8,
    parentId: 6,
    selected: false
  },
  {
    text: 'Global Rates',
    routerLink: '/future-item',
    id: 9,
    parentId: 6,
    selected: false
  },
  {
    text: 'Users',
    routerLink: '/appuser',
    svgIcon: AppPwcIcons.fill.communityIcon,
    id: 10,
    selected: false
  },
  {
    text: 'Help',
    routerLink: '/future-item',
    svgIcon: AppPwcIcons.fill.helpIcon,
    selected: false,
    id: 11
  }
];

export const NAVIGATION_COLLAPSED_ITEM = [
  {
    text: 'Funds',
    routerLink: '/fund',
    svgIcon: AppPwcIcons.fill.menuViewIcon,
    id: 0,
    selected: true,
    items: []
  },
  {
    text: 'Calculations',
    routerLink: '/scenario',
    svgIcon: AppPwcIcons.fill.calculatorIcon,
    id: 1,
    selected: false,
    items: []
  },
  {
    text: 'Source Data',
    svgIcon: AppPwcIcons.fill.sourceUploadIcon,
    id: 2,
    selected: false,
    items: [
      {
        text: 'Transaction Uploads',
        routerLink: '/upload/sourcedata-list/transactional-sourcedata',
        activeIndex: 0,
        id: 3,
        parentId: 2,
        selected: false
      },
      {
        text: 'Point in Time Uploads',
        routerLink: '/upload/sourcedata-list/point-in-time-sourcedata',
        id: 4,
        activeIndex: 1,
        parentId: 2,
        selected: false
      },
      {
        text: 'Miscellaneous Uploads',
        routerLink: '/upload/sourcedata-list/miscellaneous-sourcedata',
        id: 5,
        activeIndex: 2,
        parentId: 2,
        selected: false
      }
    ]
  },
  {
    text: 'Global Mapping',
    svgIcon: AppPwcIcons.fill.globeIcon,
    id: 6,
    selected: false,
    items: [
      {
        text: 'Global General Ledger Mapping',
        routerLink: '/general-ledger/global-mapping',
        id: 7,
        activeIndex: 0,
        parentId: 6,
        selected: false
      },
      {
        text: 'FX Gain/Loss Mapping',
        routerLink: '/general-ledger/fx-mapping',
        id: 8,
        activeIndex: 1,
        parentId: 6,
        selected: false
      },
      {
        text: 'Global Rates',
        routerLink: '/future-item',
        id: 9,
        parentId: 6,
        activeIndex: 2,
        selected: false
      }
    ]
  },
  {
    text: 'Users',
    routerLink: '/appuser',
    svgIcon: AppPwcIcons.fill.communityIcon,
    id: 10,
    selected: false,
    items: []
  },
  {
    text: 'Help',
    routerLink: '/future-item',
    svgIcon: AppPwcIcons.fill.helpIcon,
    selected: false,
    id: 11,
    items: []
  }
];
