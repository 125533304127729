import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'fdm-date'
})
export class FdmDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: string | Date, format = 'shortDate', locale?: string): string {
    if (value instanceof Date) {
      value = value.toISOString();
    }
    const result = this.datePipe.transform(value, format, undefined, locale);
    return result ?? '';
  }
}
