//region Imports

import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy, ViewEncapsulation } from '@angular/core';

import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

import { environment } from '@app-environments/environment';

import { AuthenticationService } from '@app-core/services/auth/authentication.service';
import { LoggerService } from '@app-core/services/logger.service';

import { OverlayService } from '@app-core/layout/overlay/overlay.service';
import { StoreService } from '@app-core/store/store.service';
import { AppuserModel } from '@app-shared/models/appuser.model';
import { LookupCollectionService } from '@app-shared/services/lookup-collection.service';
import { Router } from '@angular/router';

//endregion

@Component({
  selector: 'pwc-fdm-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnDestroy {
  //region Fields

  private unsubscribe$ = new Subject<void>();

  title = environment.applicationName || 'Fund Distribution Manager';

  private appuserSubject = new BehaviorSubject<AppuserModel>({
    isAuthenticated: false,
    isAuthorized: false
  } as AppuserModel);

  appuser$: Observable<AppuserModel> = this.appuserSubject.asObservable();

  displayOverlay$: Observable<boolean> = new Observable<boolean>();

  //endregion

  constructor(
    private authenticationService: AuthenticationService,
    private loggerService: LoggerService,
    private storeService: StoreService,
    private lookupCollectionService: LookupCollectionService,
    private overlayService: OverlayService,
    private router: Router
  ) {
    this.displayOverlay$ = this.overlayService.displayOverlay$;

    this.authenticationService
      .processIdentityProviderResponse()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (authorizedAppUser: AppuserModel) => {
          if (authorizedAppUser.isAuthorized) {
            this.appuserSubject.next(authorizedAppUser);

            this.loadStartUpData();

            this.storeService.setAppuser(authorizedAppUser);
            if (authorizedAppUser.isTokenRefreshed || this.router.url.includes('home')) {
              this.authenticationService.redirectTo('/fund');
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          this.loggerService.logException(err);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private loadStartUpData(): void {
    this.lookupCollectionService
      .get()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.loggerService.logMessage('App Component - Start up collection and data were loaded!');
        },
        error: (err: HttpErrorResponse) => {
          this.loggerService.logException(err);
        }
      });
  }
}
