import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { ManualAdjustmentModel } from './manual-adjustment.model';

@Component({
  selector: 'pwc-fdm-view-more-modal',
  templateUrl: './view-more-modal.component.html',
  styleUrl: './view-more-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewMoreModalComponent {
  @Input() showModal = false;

  @Input() adjustmentDetails = {} as ManualAdjustmentModel;

  @Output() doClose = new EventEmitter<boolean>();

  closeHandler(): void {
    this.doClose.emit(true);
  }
}
