/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconNewsReportFillSvgIcon: SVGIcon = {
  name: 'news_report_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/news-report-fill</title><g id="Icon/Fill/news-report-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" fill="currentColor" fill-rule="nonzero"><path d="M20.7533333,0 L20.7533333,4.12 L24,4.12 L24,21.8666667 C23.9981645,23.044113 23.044113,23.9981645 21.8666667,24 L21.8666667,24 L2.13333333,24 C0.955886978,23.9981645 0.00183546142,23.044113 0,21.8666667 L0,21.8666667 L0,0 L20.7533333,0 Z M18.2433333,8.52333333 L12.1566667,8.52333333 L12.1566667,19 L18.2433333,19 L18.2433333,8.52333333 Z M9.56,16.84 L2.89333333,16.84 L2.89333333,18.6166667 L9.56,18.6166667 L9.56,16.84 Z M16.7733333,9.74 L16.7733333,17.2966667 L13.6266667,17.2966667 L13.6266667,9.74 L16.7733333,9.74 Z M9.56,12.7666667 L2.89333333,12.7666667 L2.89333333,14.5433333 L9.56,14.5433333 L9.56,12.7666667 Z M9.56,8.75333333 L2.89333333,8.75333333 L2.89333333,10.5433333 L9.56,10.5433333 L9.56,8.75333333 Z M17.86,4.28333333 L2.89333333,4.28333333 L2.89333333,6.06666667 L17.86,6.06666667 L17.86,4.28333333 Z" id="Combined-Shape"/></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
