import { Injectable } from '@angular/core';
import { ValidatorFn } from '@angular/forms';

import isEmpty from 'lodash-es/isEmpty';
import sortBy from 'lodash-es/sortBy';
import cloneDeep from 'lodash-es/cloneDeep';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  //#region Lodash wrappers

  isEmpty<T extends object>(value?: T): boolean {
    return isEmpty<T>(value);
  }

  sortByProperties<T>(array: T[], sortingProperties: string[]): T[] {
    return sortBy(array, sortingProperties);
  }

  deepClone(value: object): object {
    return cloneDeep(value);
  }

  //#endregion

  //region Date Utility

  getCurrentDate(): Date {
    return new Date();
  }

  getCurrentYear(): number {
    return this.getCurrentDate().getFullYear();
  }

  formatDateAsIsoString(date: Date | null): string {
    if (date) {
      const utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return utcDate?.toISOString();
    }
    return '';
  }

  //endregion

  //region Number Utility

  formatNumberAsPercentage(value: number): number {
    return value ? value * 100 : 0;
  }

  formatValueAsNumber(value: number | string): number {
    return Number(value);
  }

  //endregion

  //region String Utility

  replaceString(sourceValue: string, searchValue: string, replacementValue: number | string): string {
    if (isEmpty(sourceValue)) {
      return sourceValue;
    }
    return sourceValue.replace(searchValue, replacementValue.toString());
  }

  truncateStringExceedingCount(text: string, maxCount: number, symbolMore = '...'): string {
    if (text && maxCount) {
      if (text.length <= maxCount) {
        return text;
      }
      return text.slice(0, maxCount) + symbolMore;
    }

    return '';
  }

  extractString(text: string | null, key: string, extension?: string): string {
    if (!text || !key) {
      return '';
    }
    let value = text.split(key)[1];

    value = value.split(';')[0].trim();
    value = value.replace(/^["']|["']$/g, '');

    if (extension && value.endsWith(extension)) {
      value = value.replaceAll('"', '').slice(0, -extension.length);
    }

    return value;
  }

  // NOTE Do not remove any as the request can be any type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extractFieldAsString(items: any[], field: string): string[] {
    return items.filter(item => item.active).map(item => item[field] as string);
  }

  //endregion

  //region Validators

  validateNumberWithDecimal(
    maxDigits: number,
    maxDecimalDigits: number,
    acceptNegative = false,
    acceptZero = false
  ): ValidatorFn {
    return (control: { value: string }) => {
      const value = control.value;

      if (value === null || value === undefined || value === '') {
        return null;
      }

      if (!acceptZero && value === '0') {
        return { invalidFormat: true };
      }

      const regexPattern = acceptNegative
        ? `^-?\\d{1,${maxDigits}}(\\.\\d{1,${maxDecimalDigits}})?$`
        : `^\\d{1,${maxDigits}}(\\.\\d{1,${maxDecimalDigits}})?$`;

      const regex = new RegExp(regexPattern);
      const isValid = regex.test(value);

      return isValid ? null : { invalidFormat: true };
    };
  }

  validateAlphanumericWithDash(): ValidatorFn {
    return (control: { value: string }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const regex = /^[a-zA-Z0-9_ !@#$%^&*)(+-:]+(?:[-\s][a-zA-Z0-9]+)*$/;
      const isValid = regex.test(value);
      return isValid ? null : { invalidFormat: true };
    };
  }

  validatePercentage(): ValidatorFn {
    const regex = /^((100(\.0*)?)|(\d{1,2}(\.\d*)?))%?$/;

    return (control: { value: number | string }) => {
      if (control.value) {
        const value = control.value.toString();
        if (!regex.test(value)) {
          return { invalidFormat: true };
        }

        const numericValue = parseFloat(value.replace('%', ''));
        if (numericValue < 0 || numericValue > 100) {
          return { invalidFormat: true };
        }
      }
      return null;
    };
  }
  //endregion

  copyToClipboard(text: string): string {
    if (navigator && navigator.clipboard && text && text.length > 0) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          return 'Copied to clipboard';
        })
        .catch(err => {
          return `Failed to copy to clipboard: ${err}`;
        });
    }
    return '';
  }
}
