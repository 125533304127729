import {
  fileExcelIcon,
  filePdfIcon,
  xIcon,
  searchIcon,
  filterIcon,
  pencilIcon,
  plusIcon,
  trashIcon,
  saveIcon,
  cancelIcon,
  fileIcon
} from '@progress/kendo-svg-icons';

export const AppKendoIcons = {
  cancelIcon: cancelIcon,
  fileExcelIcon: fileExcelIcon,
  fileIcon: fileIcon,
  filePdfIcon: filePdfIcon,
  filterIcon: filterIcon,
  pencilIcon: pencilIcon,
  plusIcon: plusIcon,
  saveIcon: saveIcon,
  searchIcon: searchIcon,
  trashIcon: trashIcon,
  xIcon: xIcon
};
