/* eslint-disable max-len */

import { SVGIcon } from '@progress/kendo-svg-icons';

export const pwcIconMenuViewFillSvgIcon: SVGIcon = {
  name: 'menu_view_fill',
  content:
    '<?xml version="1.0" encoding="UTF-8"?><svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><title>Icon/Fill/menu-view-fill</title><g id="Icon/Fill/menu-view-fill" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group" transform="translate(0.000000, 2.000000)" fill="currentColor" fill-rule="nonzero"><path d="M0,12 L24,12 L24,7.5 L0,7.5 L0,12 Z M0,4.5 L24,4.5 L24,0 L0,0 L0,4.5 Z M0,19.5 L14.88,19.5 L14.88,15 L0,15 L0,19.5 Z" id="Fill-v2"/></g></g></svg>',
  viewBox: '0 0 24 24'
} as SVGIcon;
