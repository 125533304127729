import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';
import { distinct, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';
import { AppKendoIcons } from '@app-shared/icons/app-kendo.icons';

interface CompositeFilterDescriptor {
  logic: 'or' | 'and';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters: any[];
}

@Component({
  selector: 'pwc-fdm-filter-multi-checkbox',
  templateUrl: './filter-multi-checkbox.component.html',
  styleUrl: './filter-multi-checkbox.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterMultiSelectComponent implements AfterViewInit {
  @Input() isPrimitive!: boolean;
  @Input() currentFilter!: CompositeFilterDescriptor;
  @Input() data: unknown[] = [];
  @Input() textField!: string;
  @Input() valueField!: string;
  @Input() filterService!: FilterService;
  @Input() field!: string;
  @Output() valueChange = new EventEmitter<number[]>();
  appKendoIcons = AppKendoIcons;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentData: any[] = [];
  showFilter = true;
  private value: unknown[] = [];

  textAccessor = (dataItem: unknown): string => {
    if (this.isPrimitive) {
      return dataItem as string;
    } else if (typeof dataItem === 'object' && dataItem !== null && this.textField in dataItem) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (dataItem as Record<string, any>)[this.textField];
    }
    return '';
  };

  public valueAccessor = (dataItem: unknown): unknown => {
    if (this.isPrimitive) {
      return dataItem;
    } else if (typeof dataItem === 'object' && dataItem !== null && this.valueField in dataItem) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (dataItem as Record<string, any>)[this.valueField];
    }
    return null;
  };

  public ngAfterViewInit(): void {
    this.currentData = this.data;
    this.value = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);
  }

  public isItemSelected(item: unknown): boolean {
    return this.value.some(x => x === this.valueAccessor(item));
  }

  public onSelectionChange(item: unknown, li: HTMLLIElement): void {
    if (this.value.some(x => x === item)) {
      this.value = this.value.filter(x => x !== item);
    } else {
      this.value.push(item);
    }

    this.filterService.filter({
      filters: this.value.map(value => ({
        field: this.field,
        operator: 'eq',
        value
      })),
      logic: 'or'
    });

    this.onFocus(li);
  }

  public onInput(e: Event): void {
    const inputValue = (e.target as HTMLInputElement).value;
    console.log('Input value:', inputValue);
    const filteredData = filterBy(this.data, {
      operator: 'contains',
      field: this.textField,
      value: inputValue
    });

    console.log('Filtered data:', filteredData);

    this.currentData = distinct(
      [
        ...this.currentData.filter(dataItem => this.value.some(val => val === this.valueAccessor(dataItem))),
        ...filteredData
      ],
      this.textField
    );

    console.log('Current data:', this.currentData);
  }

  public onFocus(li: HTMLLIElement): void {
    const ul = li.parentNode as HTMLUListElement;
    const below = ul.scrollTop + ul.offsetHeight < li.offsetTop + li.offsetHeight;
    const above = li.offsetTop < ul.scrollTop;

    // Scroll to focused checkbox
    if (above) {
      ul.scrollTop = li.offsetTop;
    }

    if (below) {
      ul.scrollTop += li.offsetHeight;
    }
  }
}
