import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'fdmPercentage'
})
export class FdmPercentagePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number | null, digitsInfo?: string, locale?: string): string | null {
    if (value === null) {
      return null;
    }

    const defaultFormat = '1.2-2';
    const formattedValue = this.decimalPipe.transform(Math.abs(value), digitsInfo ?? defaultFormat, locale);

    if (value < 0) {
      return formattedValue ? `(${formattedValue})` : '';
    }

    return formattedValue ? `${formattedValue}` : '';
  }
}
