<div id="notification-wrapper">
  <ng-template #notification let-data>
    <div>
      <div [ngClass]="{ 'k-notification-inner': true }">
        <div>
          <ng-container *ngIf="showSuccessIcon">
            <kendo-svgicon [icon]="svgIcon" size="medium" [ngClass]="{ 'success-icon': true }"></kendo-svgicon>
          </ng-container>
          <ng-container *ngIf="showErrorIcon">
            <kendo-svgicon [icon]="svgIcon" size="medium" [ngClass]="{ 'error-icon': true }"></kendo-svgicon>
          </ng-container>
          <span ngClass="k-notification-header">{{ notificationValue.title }}</span>
          <kendo-svgicon ngClass="close-icon" [icon]="closeIcon" size="medium" (click)="close()"></kendo-svgicon>
        </div>
        <div class="k-notification-description">
          {{ notificationValue.message }}
        </div>
      </div>
    </div>
  </ng-template>
</div>
