<div id="filter-multi-select">
  <ul>
    <li
      #itemElement
      *ngFor="let item of currentData; let i = index"
      (click)="onSelectionChange(valueAccessor(item), itemElement)"
      [ngClass]="{ 'k-selected': isItemSelected(item) }">
      <div ngClass="app-checkbox">
        <input type="checkbox" #notification kendoCheckBox [checked]="isItemSelected(item)" />
        <kendo-label ngClass="k-checkbox-label" [for]="notification" [text]="textAccessor(item)"></kendo-label>
      </div>
    </li>
  </ul>
</div>
