import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '@app-core/services/auth/authentication.service';

import { AppuserModel } from '@app-shared/models/appuser.model';

import { AppPwcIcons } from '@app-shared/icons/app-pwc.icons';

@Component({
  selector: 'pwc-fdm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnChanges, OnDestroy {
  @ViewChild('anchor', { static: false, read: ElementRef }) anchor!: ElementRef;

  @ViewChild('popupContainer', { static: false, read: ElementRef }) popupContainer!: ElementRef;

  @Input() title = '';
  @Input() appuser: AppuserModel | null = {} as AppuserModel;

  userInitials = '';
  menuVisible = false;
  appPwcIcons = AppPwcIcons;
  margin = { horizontal: 5, vertical: 5 };

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  ngOnChanges(): void {
    if (this.appuser?.firstName && this.appuser.lastName) {
      this.userInitials = `${this.appuser.firstName.slice(0, 1)}${this.appuser.lastName.slice(0, 1)}`;
    }
  }

  //kendo event
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.menuVisible && this.anchor) {
      const anchorElement = this.anchor.nativeElement;
      if (!anchorElement.contains(event.target)) {
        this.hideMenu();
      }
    }
  }

  ngOnDestroy(): void {
    this.hideMenu();
  }

  logoutHandler(): void {
    this.authService.logout();
    this.hideMenu();
  }

  navigateProfile(): void {
    this.router.navigateByUrl('/appuser/appuser-profile');
    this.hideMenu();
  }

  hideMenu(): void {
    this.menuVisible = false;
  }

  displayMenu(): void {
    this.menuVisible = !this.menuVisible;
  }
}
