import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { StoreState } from './store-state';

import { AppuserModel } from '@app-shared/models/appuser.model';
import { LookupCollectionModel } from '@app-shared/models/lookup/lookupCollection.model';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private initialState: StoreState = {
    lookupCollection: {
      appuser: [] as AppuserModel[]
    } as LookupCollectionModel,
    appuser: {} as AppuserModel
  };

  private storeSubject: BehaviorSubject<StoreState> = new BehaviorSubject<StoreState>(this.initialState);
  private lookupCollectionsSubject: BehaviorSubject<LookupCollectionModel> = new BehaviorSubject<LookupCollectionModel>(
    this.initialState.lookupCollection
  );
  private appuserSubject: BehaviorSubject<AppuserModel> = new BehaviorSubject<AppuserModel>(this.initialState.appuser);

  private getState(): StoreState {
    return this.storeSubject.getValue();
  }

  private setState(newState: StoreState): void {
    this.storeSubject.next(newState);
  }

  private getLookupCollectionsState(): LookupCollectionModel {
    return this.lookupCollectionsSubject.getValue();
  }

  private setLookupCollectionsState(newState: LookupCollectionModel): void {
    this.lookupCollectionsSubject.next(newState);
  }

  private getAppuserState(): AppuserModel {
    return this.appuserSubject.getValue();
  }

  private setAppuserState(newState: AppuserModel): void {
    this.appuserSubject.next(newState);
  }

  initializeState(): void {
    this.setState(this.initialState);
  }

  setAppuser(item: AppuserModel): void {
    this.setAppuserState(item);
    this.setState({ ...this.getState(), appuser: item });
  }

  getAppuser(): AppuserModel {
    return this.getAppuserState();
  }

  getLookupCollections(): LookupCollectionModel {
    return this.getLookupCollectionsState();
  }

  setLookupCollections(item: LookupCollectionModel): void {
    this.setLookupCollectionsState(item);
    this.setState({ ...this.getState(), lookupCollection: item });
  }

  getStateObservable(): Observable<StoreState> {
    return this.storeSubject.asObservable();
  }

  getLookupCollectionsObservable(): Observable<LookupCollectionModel> {
    return this.lookupCollectionsSubject.asObservable();
  }

  getAppuserObservable(): Observable<AppuserModel> {
    return this.appuserSubject.asObservable();
  }
}
