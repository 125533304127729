<div id="header">
  <kendo-appbar position="top">
    <kendo-appbar-section>
      <div class="brand-logo">
        <img ngSrc="pwcLogoSimplified.svg" alt="PwC Logo" width="42" height="42" priority />
      </div>
      <span class="appbar-title">{{ title }}</span>
    </kendo-appbar-section>
    <kendo-appbar-spacer></kendo-appbar-spacer>

    <kendo-appbar-section>
      <div class="btn-avatar-section">
        <button kendoButton #anchor class="btn-anchor" (click)="displayMenu()">
          <kendo-avatar [initials]="userInitials"></kendo-avatar>
        </button>
      </div>
      <kendo-popup *ngIf="menuVisible" [anchor]="anchor.element" (close)="hideMenu()" [margin]="margin">
        <div #popupContainer class="popup-content">
          <div class="option-item user-profile text-bold">
            <span class="option-label">{{ appuser?.firstName }} {{ appuser?.lastName }}</span>
            <!-- TODO: Needs to be removed before Production deployment -->
            <span (click)="navigateProfile()">
              <kendo-svgicon
                [icon]="appPwcIcons.outline.personIcon"
                size="large"
                class="header-menu-icon"></kendo-svgicon>
            </span>
          </div>
          <div class="option-item">
            <span class="option-label">{{ appuser?.emailAddress }}</span>
          </div>
          @if (appuser?.tenantName) {
            <div class="option-item tenant-role">
              <span class="option-label">{{ appuser?.tenantName }}</span>
            </div>
          }
          <div class="option-item user-role">
            <span class="option-label">{{ appuser?.appRoleName }}</span>
          </div>
          <!-- TODO: Needs to be developed once BE changes for User Role and TenantId is done -->
          <div class="list-items option-item">
            <div class="list-item">
              <label [for]="'role'">Current Role</label>
              <kendo-dropdownlist
                #role
                [data]="[]"
                textField="label"
                valueField="value"
                [ngClass]="'app-dropdown'"
                [svgIcon]="appPwcIcons.outline.downChevronIcon">
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  <span>{{ dataItem.label }}</span>
                </ng-template>
              </kendo-dropdownlist>
            </div>
          </div>
          <div class="logout option-item" (click)="logoutHandler()">
            <span class="option-label">
              <kendo-svgicon [icon]="appPwcIcons.fill.logoutIcon" size="large" class="header-menu-icon"></kendo-svgicon>
              Log Out
            </span>
          </div>
        </div>
      </kendo-popup>
    </kendo-appbar-section>
  </kendo-appbar>
</div>
